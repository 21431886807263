
  import { computed, defineComponent, ref, getCurrentInstance } from "vue"
  import { CollectionResourceDoc, RelationshipsWithData, ResourceObject } from '@/models/jsonapi'
  import { Contract } from "@/models/contracts";
  import { BvTableCtxObject } from "bootstrap-vue"
  import { apiDataMorpher, extractRelationshipIds } from "@/utils/apiDataHelper"
  import { resourceUrl } from '@/utils/dataExtractors'
  import Search from "@/components/shared/Search.vue"
  import { providerContractIndexColumns } from '@/view_scripts/table_columns/contract_columns'
  import { localIndex as indexResponse, fetchRelated } from "@/utils/apiDataHelper"

  export default defineComponent({
    components: {
      Search
    },
    setup(_) {
      const root = getCurrentInstance().proxy
      const totalItems = computed<number>(() => root.$store.state.contracts.pagination.totalItems)
      const getItems = async(ctx: BvTableCtxObject) => {
        const contractQueryParams = {
          'page[number]': ctx.currentPage,
          'page[size]': ctx.perPage,
          'sort': '-created_at',
          'include': 'agency,household.care_receivers.address,household.posting.visors',
          'fields[addresses]': 'first_name,last_name',
          'fields[visors]': 'designation',
        }
        await root.$store.dispatch('contracts/load2', contractQueryParams)
        const contractResponse = root.$store.state.contracts.response
        foldDataForTable(contractResponse)
        return contractResponse.data
        // // const hhIds = extractRelationshipIds(contractResponse, 'household')
        // // const crQueryParams = {
        // //   'filter[household_id]':hhIds.join(','),
        // //   'page[size]': hhIds.length*2,
        // //   'include': 'address'
        // // }
        // // await root.$store.dispatch('care_receivers/load', crQueryParams)
        // // const crResponse = root.$store.state.care_receivers.response
        // contractResponse.data.forEach((c) => {
        //   const householdAttributes = c.attributes?.household?.attributes
        //   if (householdAttributes) {
        //     householdAttributes.care_receivers = crResponse.data.filter((cr)=>{
        //       const crHouseholdRelationship = cr.relationships?.household as RelationshipsWithData
        //       const crHouseholdRelationshipData = crHouseholdRelationship?.data as ResourceObject
        //       return c.attributes?.household?.id === crHouseholdRelationshipData?.id
        //     })
        //   }
        // })
        // return contractResponse.data
      }

      const foldDataForTable = (response: any) => {
        const localIndex = indexResponse(response)
        Object.values(localIndex).forEach((obj: any) => {
          if (obj.type === 'contracts') {
            const agencyId = obj.relationships.agency.data.id
            const householdId = obj.relationships.household.data.id
            obj.attributes.agency = localIndex[agencyId]
            obj.attributes.household = localIndex[householdId]
          } else if (obj.type === 'households') {
            obj.attributes = {care_receivers: obj.relationships.careReceivers.data
            .map((entry: any) => localIndex[entry.id])}
            obj.attributes.posting = localIndex[obj.relationships.posting.data.id]
          } else if (obj.type === 'care_receivers') {
            obj.attributes = {address: localIndex[obj.relationships.address.data.id]}
          } else if (obj.type === 'postings') {
            obj.attributes = {visor: localIndex[obj.relationships.visors.data[0].id]}
          }
        })
      }

      const columns = ref(providerContractIndexColumns)

      const searchFunction = async (query: {[key: string]: string}) => {
        return root.$store.dispatch('contracts/search', query)
      }

      const searchColumns = [
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "meta.designation",
          title: "Pflegebedürftige",
          options: {hrefFunction: (data: any) => resourceUrl(data.item)}
        },
        {
          component: () => import("@/components/shared/table/cells/LinkCell.vue"),
          key: "meta.vDesignation",
          title: "Stelle",
          options: {hrefFunction: (data: any) => resourceUrl(data.item.meta.visor)}
        }
      ]

      const searchOptions = {searchFunction: searchFunction, columns: searchColumns, placeholder: 'Nach Vorname, Nachname oder Fall-Id suchen'}

      return {
        getItems,
        totalItems,
        columns,
        searchColumns,
        searchFunction,
        searchOptions
      }
    }
  })
